import React, { useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { answersType } from '../../../types';
import { TextInput } from '../../atoms/text-input';
import { RadioButton } from '../../atoms/radio-button';
import { Button } from '../../atoms/button';
import { OverflowScroll } from '../../molecules/overflow-scroll';
import { useFormValidation } from '../../../hooks/form-validation';
import { useSalesforceApi } from '../../../hooks/salesforce';
import {
	filterAnswersForSubmission,
	getDayOfCall,
	getDateOfCall,
	getNiceHoursFromValue,
} from '../../../utils';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	phoneNumber: { isValid: true },
	addressLine: { isValid: true },
	townCity: { isValid: true },
	postcode: { isValid: true },
};
export const FaceToFaceForm = ({ answers }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const addressLineRef = useRef(null);
	const townCityRef = useRef(null);
	const postcodeRef = useRef(null);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		const payloadData = {
			answers: filterAnswersForSubmission(answers),
			details: {
				firstName: formEntries.firstName,
				lastName: formEntries.lastName,
				phoneNumber: formEntries.phoneNumber,
				addressLineOne: formEntries.addressLine,
				addressLineTwo: formEntries.addressLine2,
				city: formEntries.townCity,
				postcode: formEntries.postcode,
				selectedTime: formEntries.callTime,
				selectedDate: formEntries.callWhen,
				contactType: 'On-site',
			},
		};
		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		switch (response.status) {
			case 200:
				navigate('/success', {
					state: {
						reference: response.body.identifier,
						heading: `Brilliant, that's booked in for ${getNiceHoursFromValue(
							formEntries
						)}`,
						headingEnd: getNiceHoursFromValue(formEntries),
						tracking: {
							id: 'book_a_face_to_face',
							data: {
								appointment_day: formEntries.callWhen,
								appointment_time: formEntries.callTime,
							},
						},
					},
				});
				break;
			case 403:
				navigate('/error', {
					state: {
						errorMessage:
							'Unfortunately, we’ve not been able to verify your Captcha and are unable to submit your form.',
					},
				});
				break;
			case 405:
				navigate('/error');
				break;
			case 500:
				navigate('/error');
				break;
			default:
				navigate('/error');
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef) {
			return lastNameRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.addressLine.isValid && addressLineRef.current) {
			return addressLineRef.current.focus();
		}

		if (!validations.townCity.isValid && townCityRef.current) {
			return townCityRef.current.focus();
		}

		if (!validations.postcode.isValid && postcodeRef.current) {
			return postcodeRef.current.focus();
		}
	}, [validations]);

	return (
		<form
			onSubmit={submitHandler}
			className="flex flex-col justify-between w-full mx-auto grow"
		>
			<div className="px-4 pt-10 bg-white pb-7">
				<TextInput
					labelText="First name"
					id="firstName"
					name="firstName"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter your first name"
					showValidation={!validations.firstName.isValid}
					ref={firstNameRef}
				/>

				<TextInput
					labelText="Last name"
					id="lastName"
					name="lastName"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter your last name"
					showValidation={!validations.lastName.isValid}
					ref={lastNameRef}
				/>

				<TextInput
					labelText="Best number to call you on"
					type="tel"
					id="phoneNumber"
					name="phoneNumber"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter a phone number"
					showValidation={!validations.phoneNumber.isValid}
					ref={phoneNumberRef}
				/>

				<hr className="my-10 border-blue-300 border-1 wrapper-small" />

				<h2 className="mb-6 leading-tight wrapper-small text-xl-f">
					Enter the address you’d like to meet at
				</h2>

				<TextInput
					labelText="Address line 1"
					id="addressLine"
					name="addressLine"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter your address line"
					showValidation={!validations.addressLine.isValid}
					ref={addressLineRef}
				/>

				<TextInput
					labelText="Address line 2 - optional"
					id="addressLine2"
					name="addressLine2"
					className="wrapper-small"
				/>

				<TextInput
					labelText="Town / City"
					id="townCity"
					name="townCity"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter your town or city"
					showValidation={!validations.townCity.isValid}
					ref={townCityRef}
				/>

				<TextInput
					labelText="Postcode"
					id="postcode"
					name="postcode"
					aria-required="true"
					className="wrapper-small"
					validationMessage="Please enter your postcode"
					showValidation={!validations.postcode.isValid}
					ref={postcodeRef}
				/>
			</div>

			<div className="overflow-hidden bg-brand-blue-200">
				<div className="px-4 pt-10 pb-7">
					<div className="wrapper-small">
						<hgroup className="mb-10">
							<h2 className="mb-3 leading-tight text-2xl-f">
								Let us know your preferred time
							</h2>
							<p className="text-base-f">
								Someone will be in touch to confirm. We just
								need to check travel times first.
							</p>
						</hgroup>

						<fieldset className="mb-10">
							<legend className="mb-4 text-brand-blue-400 text-base-f font-centra-medium">
								When would you like to meet?
							</legend>

							<div className="flex flex-wrap gap-1">
								<RadioButton
									defaultChecked
									labelText={getDayOfCall('Tomorrow')}
									id="callTomorrow"
									value={getDateOfCall('Tomorrow')}
									name="callWhen"
								/>
								<RadioButton
									labelText={getDayOfCall('DayAfterTomorrow')}
									id="callDayAfterTomorrow"
									value={getDateOfCall('DayAfterTomorrow')}
									name="callWhen"
								/>
							</div>
						</fieldset>

						<fieldset className="mb-6">
							<legend className="mb-4 text-xl-f">
								Pick a time that suits you
							</legend>

							<fieldset className="mb-6">
								<legend className="mb-3 text-brand-blue-400 text-base-f font-centra-medium">
									Morning
								</legend>
								<OverflowScroll
									items={[
										<RadioButton
											defaultChecked
											labelText="9:00 - 10:00am"
											key="9_10"
											id="9_10"
											value="9am-10am"
											name="callTime"
											className="mr-1"
											variant="Rect"
										/>,
										<RadioButton
											labelText="10:00 - 11:00am"
											key="10_11"
											id="10_11"
											value="10am-11am"
											name="callTime"
											className="mr-1"
											variant="Rect"
										/>,
										<RadioButton
											labelText="11:00am - 12:00pm"
											key="11_12"
											id="11_12"
											value="11am-12pm"
											name="callTime"
											variant="Rect"
										/>,
									]}
								/>
							</fieldset>

							<fieldset>
								<legend className="mb-3 text-brand-blue-400 text-base-f font-centra-medium">
									Afternoon
								</legend>
								<OverflowScroll
									items={[
										<RadioButton
											labelText="1:00 - 2:00pm"
											key="1_2"
											id="1_2"
											value="1pm-2pm"
											name="callTime"
											className="mr-1"
											variant="Rect"
										/>,
										<RadioButton
											labelText="2:00 - 3:00pm"
											key="2_3"
											id="2_3"
											value="2pm-3pm"
											name="callTime"
											className="mr-1"
											variant="Rect"
										/>,
										<RadioButton
											labelText="3:00 - 4:00pm"
											key="3_4"
											id="3_4"
											value="3pm-4pm"
											name="callTime"
											className="mr-1"
											variant="Rect"
										/>,
										<RadioButton
											labelText="4:00 - 5:00pm"
											key="4_5"
											id="4_5"
											value="4pm-5pm"
											name="callTime"
											variant="Rect"
										/>,
									]}
								/>
							</fieldset>
						</fieldset>
					</div>
				</div>
			</div>
			<div className="px-4 py-12 bg-white">
				<div className="wrapper-small">
					<Button variant="Main" type="submit">
						Submit request
					</Button>
				</div>
			</div>
		</form>
	);
};

FaceToFaceForm.defaultProps = {
	answers: [],
};

FaceToFaceForm.propTypes = {
	answers: answersType,
};
